import React from "react";
import {Typography} from "@mui/material";
import {graphql} from "gatsby";
import SiteLayout from "components/layouts/SiteLayout";
import SectionTitle from "components/atoms/SectionTitle";
import Section from "components/molecules/Section";

export default function NotInsuredPage() {
  return (
    <SiteLayout>
      <Section padding={2}>
        <SectionTitle>
          Co zrobić, gdy eWUŚ nie potwierdza mojego prawa do świadczeń?
        </SectionTitle>
        <Typography align="center" variant="subtitle1">
          EWUŚ to elektroniczny system weryfikacji uprawnień pacjentów, który umożliwia natychmiastowe potwierdzenie prawa do świadczeń finansowanych przez NFZ.
        </Typography>
      </Section>
      <Section  padding={2}>
        <Typography variant="body2" component="ol">
          <li>
            <span>
              Jeżeli jesteś ubezpieczony, zgłoś się do <b>swojego płatnika składki i sprawdź, czy zgłosił Ciebie</b> (a także członków Twojej rodziny, np. dzieci, małżonka, który nie ma pracy) <b>do ubezpieczenia zdrowotnego</b>:
            </span>
            <ul>
              <li>
                pracownik – u pracodawcy
              </li>
              <li>
                zleceniobiorca – u zleceniodawcy
              </li>
              <li>
                emeryt i rencista – w ZUS lub KRUS
              </li>
              <li>
                osoba, która pobiera zasiłek przedemerytalny lub świadczenie przedemerytalne – w ZUS
              </li>
              <li>
                osoba pobierająca zasiłek stały z pomocy społecznej – w ośrodku pomocy społecznej
              </li>
              <li>
                osoby pobierające świadczenie pielęgnacyjne lub dodatek do zasiłku rodzinnego z tytułu samotnego wychowywania dziecka i utraty prawa do zasiłku dla bezrobotnych – u wójta, burmistrza lub prezydenta miasta
              </li>
              <li>
                przedsiębiorca – w ZUS
              </li>
              <li>
                rolnik wraz z domownikami – w KRUS
              </li>
              <li>
                osoby ubezpieczające się dobrowolnie – w NFZ
              </li>
            </ul>
          </li>
          <li>
            Jeśli płatnik potwierdzi, że zostałeś zgłoszony do ubezpieczenia, weź od niego <b>dokument, który wskazuje datę zgłoszenia do ubezpieczenia zdrowotnego</b> (np. dla pracownika jest to ZUS ZUA, dla członka rodziny jest to ZUS ZCZA/ZCNA).
          </li>
          <li>
            <b>Zgłoś się do odpowiedniego oddziału NFZ z wyżej wymienionym dokumentem i dowodem osobistym</b> w celu wyjaśnienia sytuacji.
          </li>
        </Typography>
      </Section>
      <Section  padding={2}>
        <SectionTitle variant="h2">
          Jeśli jesteś pełnoletnim uczniem lub studentem do 26 r.ż.:
        </SectionTitle>
        <Typography>
          Twój status ubezpieczenia musi wyjaśnić rodzic, który zgłosił Cię do swojego ubezpieczenia u swojego płatnika składek. Jeżeli w między czasie podejmowałeś pracę, w której pracodawca zgłaszał Cię do ubezpieczenia zdrowotnego, Twój rodzic u swojego płatnika powinien raz jeszcze Ciebie zgłosić do ubezpieczenia zdrowotnego. Jeśli płatnik potwierdzi, że dokonał zgłoszenia do ubezpieczenia, rodzic powinien wziąć od niego dokument, który wskazuje datę Twojego zgłoszenia do jego ubezpieczenia jako członek rodziny (np. ZUS ZCZA/ZCNA).
        </Typography>
        <Typography>
          Powinieneś zgłosić się z wyżej wymienionym dokumentem oraz legitymacją szkolną lub studencką do NFZ.
        </Typography>
      </Section>
      <Section  padding={2}>
        <Typography
          mt={2}
          display="block"
          component="a"
          align="center"
          href="https://www.nfz.gov.pl/dla-pacjenta/ubezpieczenia-w-nfz/e-wus/faq/"
          target="_blank"
        >
          Więcej informacji o ubezpieczeniu w NFZ
        </Typography>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
